import logo from './logo.svg';
import './App.css';

import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import ChatUI from './ChatUI';
import { ModelDialog } from './ModelDialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { flexbox, maxWidth } from '@mui/system';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
  IconButton,
  Tooltip
} from "@mui/material";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MESSAGE_TYPES from './messageTypes';
import LOADING_STATUS from './loadingStatus';

const defaultMessages = [
];
const defaultDocuments = [];
const defaultId = -1;
const defaultDialogOpenState = true;
const defaultBedrockModel = "";
const defaultUploadStatus = [];
var messageId = 0;

const getAccessToken = () => {
  var currentDate = new Date();
  var accessToken = sessionStorage.getItem('access-token');
  if (accessToken) {
    accessToken = JSON.parse(accessToken);
    var expirationDate = accessToken.expiresAt;
    if (Date.parse(currentDate) < Date.parse(expirationDate)) {
      //accessToken is not expired
      return accessToken.token;
    } else {
      sessionStorage.removeItem('access-token');
      console.log('access-token expired');
    }
  }
  return false;
}

export const WebSocketDemo = () => {
  const token = getAccessToken()
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState('wss://mqnlz3ny4l.execute-api.us-east-1.amazonaws.com/LATEST?token=' + token);
  const [messageHistory, setMessageHistory] = useState([]);
  const [s3uploadurl, setS3uploadurl] = useState("none");
  const [conversationType, setConversationType] = useState("CONVERSATION");
  const [messages, setMessages] = useState(defaultMessages);
  const [documents, setDocumentHistory] = useState(defaultDocuments);
  const [sessionId, setSessionId] = useState(defaultId);
  const [showModelDialog, setShowModelDialogState] = useState(defaultDialogOpenState);
  const [bedrockModel, setBedrockModel] = useState(defaultBedrockModel);
  const [uploadStatus, setUploadStatus] = useState({});
  var { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket, } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => { return true; },
    reconnectAttempts: 1000,
    reconnectInterval: 3000,
  });

  const appendToDialog = (sender, content, type, loading) => {
    setMessages((prev) => prev.concat(
      {
        id: messageId++,
        text: content,
        sender: sender,
        type: type,
        loading: loading
      }
    ));
  }
  // var ws = new WebSocket(socketUrl);

  const handleClickSendMessage = useCallback((content) => {
    var message = {};
    message.type = "chatMessage";
    message.content = content;
    message.conversationType = conversationType;
    appendToDialog("user", message.content, MESSAGE_TYPES.MESSAGE);
    sendMessage(JSON.stringify(message));
  }, []);

  const getS3UploadUrl = (filename) => {
    var message = {}
    message.type = "generateUploadUrl";
    message.content = filename
    sendMessage(JSON.stringify(message));
  }

  const fileChangedCallback = (file) => {
    getS3UploadUrl(file.name)
  }
  const heartBeat = () => {
    const message = { "type": "heartBeat", "content": "ping" };
    sendMessage(JSON.stringify(message));
  }
  useEffect(() => {

    //Implementing the setInterval method
    const interval = setInterval(heartBeat, 2000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const handleUpload = (file) => {
    uploadStatus[file.name] = new Set(defaultUploadStatus)
    appendToDialog("Mnemosyne", file.name, MESSAGE_TYPES.DATA_UPLOAD);
    var data = new FormData()
    data.append('key', s3uploadurl["fields"]["key"])
    data.append('AWSAccessKeyId', s3uploadurl["fields"]["AWSAccessKeyId"])
    data.append('policy', s3uploadurl["fields"]["policy"])
    data.append('signature', s3uploadurl["fields"]["signature"])
    data.append('x-amz-security-token', s3uploadurl["fields"]["x-amz-security-token"])
    data.append('file', file)
    const requestOptions = {
      method: 'POST',
      body: data
    };
    fetch(s3uploadurl.url, requestOptions)
      .then(response => console.log(response));
    setS3uploadurl("none")
  }

  const handleConversationTypeChange = (event) => {
    setConversationType(event.target.value);
  }

  const updateDocumentHistory = (newDocumentList) => {
    setDocumentHistory(newDocumentList);
  }

  const handleModelSelection = (selectedModel) => {
    // console.log("Model selected");

    setBedrockModel((bedrockModel) => {

      // console.log("MODEL");
      // console.log(selectedModel);
      bedrockModel = selectedModel;
      // console.log(bedrockModel);
      var message = {};
      message.type = "helloMessage";
      message.content = selectedModel;
      sendMessage(JSON.stringify(message));

      //Prompt model to introduce itself
      message = {};
      message.type = "chatMessage";
      message.content = "Introduce yourself and explain your capabilities.";
      sendMessage(JSON.stringify(message));
      setShowModelDialogState(showModelDialog => showModelDialog = false);
    });
  }
  const handleCancleModelDialog = () => {
    setShowModelDialogState(showModelDialog => showModelDialog = false);
  }
  const openModelDialog = () => {
    setShowModelDialogState(showModelDialog => showModelDialog = true);
  }
  
  useEffect(() => {
    if (lastMessage !== null) {
      try {
        var message = JSON.parse(lastMessage.data);

        if (message.type == "chatMessage") {
          // setUploadStatus((uploadStatus) => uploadStatus = []);
          appendToDialog("Mnemosyne", message.content, MESSAGE_TYPES.MESSAGE);
          // appendToDialog("Mnemosyne", "", MESSAGE_TYPES.DATA_UPLOAD);
          setMessageHistory((prev) => prev.concat({ data: message.content }));
        }
        if (message.type == "streamDone") {
          var idx = messages.length -1
          messages[idx].loading = false
        }
        if (message.type == "chatMessageStream") {
          if(message.index == 0){
            // setUploadStatus((uploadStatus) => uploadStatus = []);
            appendToDialog("Mnemosyne", message.content, MESSAGE_TYPES.MESSAGE, true);
            // appendToDialog("Mnemosyne", "", MESSAGE_TYPES.DATA_UPLOAD);
            setMessageHistory((prev) => prev.concat({ data: message.content }));
          }
          else {
            var idx = messages.length -1
            messages[idx].text += message.content
            //setMessageHistory((prev) => prev[idx].data += message.content);
            //setMessages((prev) => prev[idx].text += message.content);
          }
        }
        if (message.type == "generateUploadUrl") {
          setS3uploadurl(message.content)
        }
        if (message.type == "documentList") {
          updateDocumentHistory(message.content);
        }
        if (message.connectionId) {
          setSessionId(id => id = message.connectionId);
        }

        if (message.type == LOADING_STATUS.DOCUMENT_UPLOADED && !uploadStatus[message.content].has(LOADING_STATUS.DOCUMENT_UPLOADED)) {
          setUploadStatus((uploadStatus) => {
            uploadStatus[message.content].add(LOADING_STATUS.DOCUMENT_UPLOADED);
            return uploadStatus;
          });
          console.log(LOADING_STATUS.DOCUMENT_UPLOADED);
        }

        if (message.type == LOADING_STATUS.TEXT_EXTRACTED && !uploadStatus[message.content].has(LOADING_STATUS.TEXT_EXTRACTED)) {
          setUploadStatus((uploadStatus) => {
            uploadStatus[message.content].add(LOADING_STATUS.TEXT_EXTRACTED);
            return uploadStatus;
          })
          console.log(LOADING_STATUS.TEXT_EXTRACTED);
        }

        if (message.type == LOADING_STATUS.EMBEDDING_DOCUMENT_CREATED && !uploadStatus[message.content].has(LOADING_STATUS.EMBEDDING_DOCUMENT_CREATED)) {
          setUploadStatus((uploadStatus) => {
            uploadStatus[message.content].add(LOADING_STATUS.EMBEDDING_DOCUMENT_CREATED);
            return uploadStatus;
          })
          console.log(LOADING_STATUS.EMBEDDING_DOCUMENT_CREATED);
        }

        if (message.type == LOADING_STATUS.EMBEDDING_DOCUMENT_EMBEDDED && !uploadStatus[message.content].has(LOADING_STATUS.EMBEDDING_DOCUMENT_EMBEDDED)) {
          setUploadStatus((uploadStatus) => {
            uploadStatus[message.content].add(LOADING_STATUS.EMBEDDING_DOCUMENT_EMBEDDED);
            return uploadStatus;
          })
          console.log(LOADING_STATUS.EMBEDDING_DOCUMENT_EMBEDDED);
        }

        if (message.type == LOADING_STATUS.SUMMARY_GENERATED && !uploadStatus[message.content].has(LOADING_STATUS.SUMMARY_GENERATED)) {
          setUploadStatus((uploadStatus) => {
            uploadStatus[message.content].add(LOADING_STATUS.SUMMARY_GENERATED);
            return uploadStatus;
          })
          console.log(LOADING_STATUS.SUMMARY_GENERATED);
        }

      }
      catch (e) {
        setMessageHistory((prev) => prev.concat({ data: "ERROR" }));
      }
    }
  }, [lastMessage, setMessageHistory]);


  // setTimeout(() => {
  //   appendToDialog("Mnemosyne", "", MESSAGE_TYPES.DATA_UPLOAD);
  // }, 10000)

  return (
    <div>
      {
        showModelDialog ? <ModelDialog open={showModelDialog} setSelectedModel={handleModelSelection} handleCancel={handleCancleModelDialog} modelSelected={bedrockModel != ""}></ModelDialog> : null
      }
      <Grid container
        direction="row"
        alignItems="center"
        sx={{ backgroundColor: 'white', width: '100%', height: '5vh', 'padding-left': '1%' }}>
        <Grid item xs={6}>
          <span style={{ 'font-size': '2.0rem' }}>Mnemosyne Document Assistant</span>
        </Grid>
        <Grid item container xs={6}
          direction="row"
          alignItems="center">
          <Grid item xs={4}>
          </Grid>
          <Grid item container
            direction="row"
            justifyContent="flex-end"
            alignItems="center" xs={4}>
            {
              documents.map((doc) => (
                <Tooltip title={doc}>
                  <DescriptionOutlinedIcon sx={{ 'font-size': '2.0rem' }}></DescriptionOutlinedIcon>
                </Tooltip>
              ))
            }
          </Grid>
          <Grid item container
            direction="row"
            justifyContent="flex-end"
            alignItems="center" xs={3}>
            <span>Session Id: {sessionId}</span>
          </Grid>
          <Grid item container
            direction="row"
            justifyContent="flex-end"
            alignItems="center" xs={1}>
            <IconButton onClick={openModelDialog}><SettingsOutlinedIcon sx={{ 'font-size': '2.0rem', color: 'black' }}></SettingsOutlinedIcon> </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <ChatUI messages={messages} sendCallback={handleClickSendMessage} uploadCallback={handleUpload} fileChangedCallback={fileChangedCallback} uploadStatus={uploadStatus} uploadDisabled={s3uploadurl == "none"}> </ChatUI>
    </div>
  );
};



function Main() {
  return WebSocketDemo();
}

export default Main;
