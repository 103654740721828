
import * as React from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'

const defaultModel = "Claude v2.0";
const bedrockModels = ["Claude v1.3", "Claude v2.0", "Claude Instant v1.3", "Jurassic-2 Ultra v1.0", "Jurassic-2 Mid v1.0", "Titan Text Large v1.01"];
const modelIdMapping = {
    "Claude v1.3": "anthropic.claude-v1", "Claude v2.0": "anthropic.claude-v2", "Claude Instant v1.3": "anthropic.claude-instant-v1",
    "Jurassic-2 Ultra v1.0": "ai21.j2-ultra-v1", "Jurassic-2 Mid v1.0": "ai21.j2-mid-v1", "Titan Text Large v1.01": "amazon.titan-tg1-large"
}

export const ModelDialog = (props) => {
    const [model, setModel] = React.useState(defaultModel);
    var open = props.open;
    var bedrockModelPreSelected = props.modelSelected;
    // console.log("PROPERTIES");
    // console.log(props);

    const handleModelChange = (event) => {
        // console.log("Handle Model Change");
        // console.log("Old Model" + model);
        setModel(model => model = event.target.value);
        // console.log("New Model" + model);
    }

    const saveSelectedModel = (event) => {
        props.setSelectedModel(modelIdMapping[model]);
    }

    const handleCancelDialog = (event) => {
        if (props.handleCancel) {
            props.handleCancel();
        }

    }
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Model Selection"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please select your desired Large Language Model for the conversation with Mnemosyne.

                    </DialogContentText>
                    <DialogContentText>
                        <br></br>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={model}
                            label="Models"
                            onChange={handleModelChange}
                        >
                            {
                                bedrockModels.map(model => (
                                    <MenuItem value={model}>{model}</MenuItem>
                                ))
                            }
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveSelectedModel}>Save</Button>
                    <Button disabled={!props.modelSelected} onClick={handleCancelDialog}>
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}