import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Main from "./Main";
import signIn from "./signIn";

// S1 Sign-In Page
const Home = () => {
  return (
    signIn()
  );
};

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/main" element={<Main />} />
          <Route path="*" element={<Navigate replace to="/main" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}