import * as React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { LoadingBar } from "./LoadingBar";
import MESSAGE_TYPES from "./messageTypes";
import { useRef, useEffect } from 'react';
import { ThreeDots } from  'react-loader-spinner'

const DATA_UPLOAD_TEXT = "Thanks for your input. That looks very interesting. I´m processing the content now so we can have an exciting conversation."

const ChatUI = (props) => {
  var messages = props.messages;
  var uploadStatus = props.uploadStatus;
  const [file, setFile] = React.useState("");
  const [input, setInput] = React.useState("");
  const uploadDisabled = props.uploadDisabled;
  // const [file, setFile] = React.useState("");
  const handleSend = () => {
    if (input.trim() !== "") {
      // console.log(input);
      setInput("");
      if (props.sendCallback) {
        props.sendCallback(input);
      }
    }
  };
  const uploadFile = (event) => {
    // console.log(file);
    if (props.uploadCallback) {
      props.uploadCallback(file);
    }
  }
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
  const handleInputChangeCallback = (file) => {
    setFile(file);
    if (props.fileChangedCallback) {
      props.fileChangedCallback(file);
    }
  };
  return (
    <Box
      sx={{
        height: "98vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.200",
        position: "fixed",
        width: "100%"
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {messages.map((message) => (
          <Message key={message.id} message={message} handleInputChangeCallback={handleInputChangeCallback} uploadCallback={uploadFile} first={message.id == messages[0].id} uploadStatus={uploadStatus} uploadDisabled={uploadDisabled}/>

        ))}
      </Box>
      <Box sx={{ p: 2, backgroundColor: "background.default", height: "9vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={10} sx={{ "padding-bottom": "20px" }}>
            <TextField
              size="small"
              fullWidth
              placeholder="Type a message"
              variant="outlined"
              value={input}
              onChange={handleInputChange}
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  handleSend()
                  // Do code here
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSend}
            >
              Send
            </Button>
          </Grid>
        </Grid>
        {/* <div>
            <input type="file"   name="file" onChange={handleInputChangeCallback} /> <Button onClick={uploadFile}>Upload</Button>
          </div> */}
      </Box>
    </Box>
  );
};

const Message = ({ message, uploadCallback, handleInputChangeCallback, first, uploadStatus,uploadDisabled }) => {
  const isBot = message.sender === "Mnemosyne";
  const divRef = useRef(null);

  const uploadFile = (file) => {
    // console.log(file);
    if (uploadCallback) {
      uploadCallback(file);
    }
  }
  const handleInputChange = (event) => {
    // setFile(event.target.files[0]);
    if (handleInputChangeCallback) {
      handleInputChangeCallback(event.target.files[0])
    }
  };
  return (
    <Box  ref={divRef}
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: isBot ? "primary.main" : "secondary.main" }}>
          {isBot ? "M" : "U"}
        </Avatar>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot ? "primary.light" : "secondary.light",
            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          }}
        >
          {message.type == MESSAGE_TYPES.DATA_UPLOAD &&

            <Typography variant="body1">{DATA_UPLOAD_TEXT}</Typography>

          }
          {message.type == MESSAGE_TYPES.DATA_UPLOAD &&

            <br></br>

          }
          {message.type == MESSAGE_TYPES.DATA_UPLOAD &&

            <LoadingBar uploadStatus={uploadStatus} documentName={message.text}></LoadingBar>

          }

          {message.type == MESSAGE_TYPES.MESSAGE &&
            <Typography variant="body1">{message.text} {message.loading && <ThreeDots 
            height="30" 
            width="30" 
            radius="5"
            color="#4fa94d" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
             />}</Typography>
            
          }
          {first &&
            <div>
              <input type="file" name="file" onChange={handleInputChange} /> <Button disabled={uploadDisabled} onClick={uploadFile}>Upload</Button>
            </div>
          }
        </Paper>
      </Box>
    </Box>
  );
};

export default ChatUI;