import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {
    Box,
    Grid
} from "@mui/material"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const LOADING_STATUS = {
    DOCUMENT_UPLOADED: "documentUploaded",
    TEXT_EXTRACTED: "textExtracted",
    EMBEDDING_DOCUMENT_GENERATED: "embeddingDocumentGenerated",
    EMBEDDING_DOCUMENT_EMBEDDED: "embeddingDocumentEmbedded",
    SUMMARY_GENERATED: "summaryGenerated"

}

const uploadStatusTextMapping = { "documentUploaded": "Document uploaded", "textExtracted": "Text extracted", "embeddingsDocumentsCreated": "Embedding documents created", "embeddingDocumentEmbedded": "Embedding document embedded", "summaryGenerated": "Summary generated" };
// const defaultAchievedStatus = ["Document uploaded", "Text extracted", "Embedding documents generated"];
// const defaultOpenStatus = ["Embedding document embedded", "Summary generated"];
// const uploadStati = [{}, {}, {}, {}, {}]
const defaultAchievedStatus = [];
const defaultOpenStatus = ["Document uploaded", "Text extracted", "Embedding documents created", "Embedding document embedded", "Summary generated"];
export const LoadingBar = (props) => {
    //var uploadStatus = Array.from(new Set(props.uploadStatus[props.documentName].map((item) => item.id)))
    var uploadStatus = Array.from(props.uploadStatus[props.documentName]);
    console.log(uploadStatus)
    return (
        <Box sx={{ flexGrow: 1, width: "100vh" }}>
            <Grid container direction="row"
                alignItems="center">
                    {
                        uploadStatusTextMapping &&
                        Object.keys(uploadStatusTextMapping).map((status) => (

                        <Grid item container direction="column" xs={1} alignItems="center">
                            <Grid item direction="row" alignItems="center" >
                                <Tooltip title={uploadStatusTextMapping[status]}>
                                    {uploadStatus.includes(status) &&
                                        <CheckCircleOutlineOutlinedIcon sx={{ 'font-size': '3.5rem', color: 'green' }}></CheckCircleOutlineOutlinedIcon>
                                    }
                                    {!uploadStatus.includes(status) && 
                                        <ProgressSpinner></ProgressSpinner>
                                    }
                                </Tooltip>
                            </Grid>
                        </Grid>
                        ))
                    }
            </Grid>
            <br />
            <BorderLinearProgress sx={{
                // color: (theme) =>
                //     theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                '.MuiLinearProgress-bar': {
                    'background-color': 'green',
                }
                // 'background-color': 
            }} variant="determinate" value={(uploadStatus.length / Object.keys(uploadStatusTextMapping).length) * 100} />
        </Box >

    );
}


const ProgressSpinner = (props) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    // color: (theme) =>
                    //     theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                    color: 'green',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}